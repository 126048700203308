<template>
    <split-pane  :min-percent='10' :default-percent='12' split="vertical">
        <template slot="paneL"> 
            <div style="line-height:30px;margin-top:1px;background:white;border-bottom:1px solid #ccc">
                <el-link :underline="false" @click="addTree" style=" font-size:10px;"><i class="iconfont icon-xinzeng" style=" font-size:14px;color:#023974"></i>新增 </el-link>
                <el-link :underline="false" @click="editTree" style="margin-left:15px;font-size:10px;"><i class="iconfont icon-bianji" style=" font-size:14px;"></i>编辑 </el-link>
                <el-link :underline="false" @click="delTree" style="margin-left:15px;font-size:10px;"><i class="iconfont icon-shanchu" style=" font-size:14px;"></i>删除 </el-link>
            </div>
            <el-tree :data="treeData" 
                        :props="defaultProps" 
                        @node-click="handleNodeClick" 
                        node-key="id"      
                        :default-expanded-keys="['0']"                
                        :style="{background:'#fff',overflow:'auto',height: tabHigh}"
                        highlight-current
                        ref="eltree"
                        :expand-on-click-node="false">
                <template v-slot="{node,data}">   
                    <span v-if="data.id ==0 "  >
                        <span  style="color:#409e12;font-size:14px;"><i class="iconfont icon-mokuai1" style="color:#409eff;font-size:18px;"></i> {{ node.label}} </span>
                    </span>                  
                    <span v-else  > 
                        <span style="color:#606266;font-size:12px;"><i class="iconfont icon-fenzhijiedian" style="margin-right:5px;"></i> {{ node.label}}  </span>
                    </span>                                  

                </template>
            </el-tree>
        </template>
        <template slot="paneR"> 
            <div style="background:white;line-height:30px;">
                <i class="iconfont icon-xinzeng" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                <el-link   @click="addRec" :underline="false" style="margin-top:0px;"><span style="font-size:13px;margin-right:15px;;color:#023974">新增 </span></el-link>
                <span style=" margin-left:20px;font-size:12px;margin-right:10px;">字段查询</span>
                <el-select v-model="selparam" size="small" placeholder="请选择列名" clearable  style="width:150px; " >
                    <span v-for="item in tabCol" :key='item.key'>
                        <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                    </span>
                </el-select>
                <el-input :placeholder="input_hold" v-model="textParam" @keyup.enter.native="stfgChange" size="small" style="width:320px;margin-left:10px;height:30px;margin-top:3px;">
                    <el-select v-model="oper" slot="prepend"   style="width:100px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                        </span>
                    </el-select>
                    <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
                </el-input>
            </div> 
            <div >
            <el-table  :data="lanData" 
                border                            
                size="small"
                :height="tabHigh" 
                :row-key="getRowKey"
                ref="refdtl"
                highlight-current-row > 
                <span v-for="(item,index ) in tabCol" :key="index" > 
                    <el-table-column   v-if="item.thide=='N'"
                        :prop="item.key"
                        :fixed="item.key==='RN'"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        sortable
                        :min-width="item.width"
                        > 
                    </el-table-column>    
                </span> 
                <el-table-column
                    fixed="right"
                    label="操作"
                    align="center"
                    width="140">
                    <template slot-scope="scope">
                        <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="certEdit(scope.row,scope.$index )">编辑</el-button>
                        <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="certDel(scope.row,scope.$index )">删除</el-button>  
                    </template>
                </el-table-column>  
            </el-table>
            </div>
            <!--  SEO SET -->
            <el-dialog  :show-close="false" v-dialogDrag :visible.sync="addWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
                <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                    <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{addFlag==='create'?'新增记录':'编辑记录'}}</span>
                </div> 
                <Form :model="certRow" :label-width="120"  label-position="right" inline :rules="ruleValidate">  
                    <span v-for="(item,index) in tabCol" :key="index" >
                        <FormItem  :label="item.title"  :class="(item.key=='ENAME'||item.key=='CNAME' ) ?'item-width2':'item-width'" :prop="item.key"  v-if="item.fhide==='N'">                  
                            <el-input-number   v-if="item.edcss==='number' "  v-model="certRow[item.key]" style="width:100%"></el-input-number>
                            <el-input  type="textarea" v-else-if="item.edcss=='textarea'"  :rows="4" v-model="certRow[item.key]"></el-input>
                            <el-input  type="text" v-else :disabled="item.disabled==='Y'?true:false"  v-model="certRow[item.key]"></el-input>
                        </FormItem>
                    </span>

                </Form>
                <div style="line-height:30px;color:red ;background:#f7f8f9"> 
                    提示：*** 字段编码都用小写，且在一个页面中是不重复的  ***
                </div>
                <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px;  ">
                    <span style="float:right">
                        <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="addWin=false" style="margin-right:50px;">取消</el-button>
                        <el-button type="primary" size="small" icon="iconfont icon-baocun" @click="saveRec"  style="margin-right:10px;">保存</el-button>
                    </span>
                </div>
            </el-dialog>
            <!-- 提示框 -->
            <el-dialog  :visible.sync="treeWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
                <div slot="title" :style="{'background':'#67c23a','color':'white','height':'35px'}">
                    <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
                </div>
                <span >页面名称</span><el-input v-model="tree_name" placeholder="请输入"></el-input>
                <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; margin-top:15px; ">
                    <span style="float:right">
                        <el-button type="text" icon="iconfont icon-shanchu"  @click="treeWin=false" style="margin-right:50px;">取消</el-button>
                        <el-button type="text" icon="iconfont icon-baocun" @click="saveTree"  style="margin-right:10px;">保存</el-button>
                    </span>
                </div>
            </el-dialog>
            <!-- 提示框 -->
            <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
                <div slot="title" :style="{'background':'#67c23a','color':'white','height':'35px'}">
                    <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
                </div>
                <div style=" margin:15px 10px">{{prompt}}</div>
                <div slot="footer">
                    <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
                </div>
            </el-dialog>
        </template>
        
    </split-pane>
</template>
<script>
 
 
import { getBaseData,getTabColOrData } from '../../api/user'
import {  paramData } from '../../api/Select' 
import {  uuid } from '../../api/Tools' 
import treeBarVue from '@/components/echarts/treeBar.vue'
 
//局部注册
export default {
    name:"language_set",
    data () {
        return {
            stfgBool:true,
            ruleValidate:{},
            operArr:[],  
            selparam:'CNAME',
            textParam:'',
            input_hold:'请输入查询值',
            oper:'like',
            defaultProps: 
            {  //el-tree 默认字段属性
                children: 'children',
                id:'id',
                icon:'icon',
                label: 'label'
            },
            tabCol:[],
            lanData:[],
            addWin:false,
            addFlag:'create',
            treeData:[],
            certRow:{},
            nodeId:'0',
            v_url:this.$store.state.queryUrl , //api请求路径    
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            promptWin:false,
            prompt:'',
            notNull:[],
            treeWin:false,
            tree_name:'',
            v_subno:'',
            nodeLabel:'',
        }
    },
    mounted () {   
        this.getLangList(this.nodeId,'') 
        //动态生成表单验证规则
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','45818','fty','HEAD','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            this.notNull = res.data
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空'}
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.ruleValidate =ruleObj
        });
        //操作符
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        });  
    },
    computed: {
 
        tabHigh: function() {
            return (window.innerHeight - 125) + 'px';
        }, 
    },
    components: {

    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
            this.getData()
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }     
    },
    watch: {
 
    },
    methods: { 
        // 获取 树形控件数据
        getData() {  
            var vm=this;
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.v_username+'&password='+vm.v_password,
                data: {p_table:'V_BS_PAGE'}
            }).then(function(res){                  
                vm.treeData =res.data;   
                if(res.data.length > 0 ){
                    vm.$nextTick().then(() =>{
                        //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                        vm.$refs.eltree.setCurrentKey(vm.nodeId)
                    })
                }    
            })                                       
        },
        handleNodeClick(data){
            this.nodeLabel =data.label    
            this.nodeId =data.id
            this.getLangList(data.id,'')
        }  ,
        saveTree(){
            if (this.tree_name){
               let v_row={'IDSEQ':'','PARENTNO':'0','CODE':this.v_subno,'CNAME':this.tree_name} 
               this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(v_row), p_table:'bs_page',frmid:'45818' },
                }).then(res=>{
                    if (res.data.code!=='200'){
                        this.promptWin=true
                        this.prompt=res.data.result
                    }else{
                        this.getData()
                        this.treeWin=false
                    }
                })    
            }else{
                this.promptWin=true
                this.prompt ='页面名称不能为空'
            }
        },
        // 保存
        saveRec(){
            this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                if (!this.certRow[item.FLDNO] ){
                    this.stfgBool =false 
                    this.promptWin=true
                    this.prompt=item.FLDNM+'不能为空'
                    return true
                }else{
                    this.stfgBool =true  
                }
            })
            //发起保存请求   
            if (this.stfgBool) {   
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/tableBodySave?username='+this.v_username +'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.certRow), p_table:'bs_pagedtl',p_idseq:''},
                }).then(res=>{
                    if (res.data.code!=='200'){
                        if (res.data.result.indexOf('ORA-00001')>-1){
                            this.promptWin=true
                            this.prompt='字段编码重复，请核查'
                        }else{
                            this.promptWin=true
                            this.prompt=res.data.result
                        }
                    }else{
                        this.getLangList(this.nodeId,'')
                        this.addWin=false
                    }
                })    
            }   
        },
        addTree(){
            this.treeWin =true
            this.v_subno=''
            this.tree_name=''
        },
        editTree(){
            if (this.nodeId=='0'){
                this.promptWin=true
                this.prompt='根结点不能编辑'
            }else{
                this.v_subno =this.nodeId
                this.treeWin =true
                this.tree_name=this.nodeLabel
            }
        },
        //add record
        addRec(){
            if (this.nodeId=='0'){
                this.promptWin=true
                this.prompt ='请选择页面'
            }else{
                this.certRow={IDSEQ:this.nodeId }
                this.addWin=true
                this.addFlag='create'
            }
        },
 
        getRowKey(row){
            return row.IDSEQ +'-'+row.LSTSEQ
        },
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','45818','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.tabCol.push({  
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        edcss: res.data[k].EDCSS,
                        thide: res.data[k].THIDE,
                        fhide: res.data[k].FHIDE,
                        width: res.data[k].width,
                        sortable: res.data[k].sortable?true:false,
                        disabled: res.data[k].DISABLED,
                    })
                }
            });
 
        },
        delTree(){
            if (this.nodeId ==='0'){
                this.promptWin=true
                this.prompt='根节点不能删除'
            }else{
                this.$confirm('你确定要删除此页面吗?','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    type:'info',
                }).then(()=>{
                    //删除
                    this.$axios({   
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'code',p_value:this.nodeId,  p_table:'bs_page'},
                    }).then(res=>{
                        if (res.data.code=='200'){
                            this.getData()
                        }else{
                            this.promptWin=true
                            this.prompt=res.data.result
                        }
                    })
                }) 
            }
        },
        certDel(row,index){
            this.$confirm('你确定要删除此记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                console.log('vlaue:'+row['IDSEQ'])
                //删除
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ,  p_table:'bs_pagedtl'},
                }).then(res=>{
                    this.lanData.splice(index,1 )
                })
           }) 
        },
        certEdit(row,index){
            this.addFlag='edit'
            this.certRow=JSON.parse(JSON.stringify(row))
            this.addWin=true
        },
 
        // 查询明细 
        getLangList(v_idseq,cond){
            this.lanData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_PAGEDTL','idseq',v_idseq,'','','','',cond ).then((res) => { 
                this.lanData =res.data 
            })
            setTimeout(() => {
                this.$refs.refdtl.doLayout()
            }, 500);
 
 
        },
        //状态查询
        stfgChange(){
            let cond_=''
            if (this.selparam){
                if (this.oper==='like'){
                    cond_=cond_+' and '+this.selparam+' like \'%'+this.textParam+'%\''
                }else{
                    cond_=cond_+' and '+this.selparam+this.oper+'\''+this.textParam+'\''
                }
            }
            this.getLangList(this.nodeId, cond_)
        },

        
   
    }
}
    
</script>
<style lang="less">
 
 
    //调整
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:0px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    // .el-dialog__headerbtn i { background:white;font-size: 20px;  }
</style>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    .item-width2  {
        width: 97%;
        color:#409EFF;
    }
 
    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
 
 
</style>
 